import React from "react"
import { inject, observer } from "mobx-react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Helmet from "react-helmet"


import Layout from "../components/layout"
import colors from "../components/colors"
import Workshop from "../components/workshop"
import ContactBanner from "../components/ContactBanner"
import Newsletter from "../components/Newsletter"
import Medium from "../components/medium"

import de_webinars from "../../content/de_webinars.json"
import en_webinars from "../../content/en_webinars.json"
import de_workshops from "../../content/de_workshops.json"
import en_workshops from "../../content/en_workshops.json"
import settings from '../../content/settings.json'

// data fields to add: workshop visual,

const Insights = props => {
  
  en_workshops.workshop.map(
    item =>
      (item.url = `/workshop/${item.title
        // eslint-disable-next-line
        .replace(/\s+|\?|\#/g, '-')
        .toLowerCase()}/`)
  )
  de_workshops.workshop.map(
    (item, i) =>
      (item.url = `/workshop/${en_workshops.workshop[i].title
        // eslint-disable-next-line
        .replace(/\s+|\?|\#/g, '-')
        .toLowerCase()}/`)
  )
  en_webinars.webinar.map(
    item =>
      (item.url = `/webinar/${item.title
        // eslint-disable-next-line
        .replace(/\s+|\?|\#/g, '-')
        .toLowerCase()}/`)
  )
  de_webinars.webinar.map(
    (item,i) =>
      (item.url = `/webinar/${en_webinars.webinar[i].title
        // eslint-disable-next-line
        .replace(/\s+|\?|\#/g, '-')
        .toLowerCase()}/`)
  )
  const { language, device } = props.store
  const styles = device === 'desktop'? desktopStyles : mobileStyles
  const { webinarDescription } = language === "en" ? en_webinars : de_webinars
  const { workshopDescription } = language === "en" ? en_workshops : de_workshops
  const { insightsDescription } = language === "en" ? en_workshops : de_workshops
  const workshops =
    language === "en" ? en_workshops.workshop : de_workshops.workshop
  const webinars = language === "en" ? en_webinars.webinar : de_webinars.webinar
  return (
    <Layout>
    
    <Helmet>
          <title>{'LT | Insights'}</title>
          <meta property="og:image" content={settings.insightsSocialSharingImage}/>
          <meta property="og:title" content={'Liquiditeam - Tokenization Workshops and Webinars'}/>
          <meta property="og:description" content={insightsDescription}/>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@liquiditeam" />
          <meta name="twitter:title" content={"Liquiditeam - Tokenization Workshops and Webinars"} />
          <meta name="twitter:description" content={insightsDescription} />
          <meta name="twitter:image" content={'https://liquidi.team'+settings.insightsSocialSharingImage} />
        </Helmet>
    <div style={{position:'relative'}}>
      <div style={styles.container}>
        <img
        style={{width:'100%', maxHeight:'150px', objectFit:'cover'}}
        src={en_workshops.insightsVisual}
        alt="Fans cheering"
      />
        <div style={styles.contentContainer}>
          <div style={styles.upperSection}>
            <div style={styles.breadCrumbContainer}>
              <Link to={"/"} style={styles.link}>
                Home
              </Link>
              {" / "}
              Insights
            </div>
            <div style={{paddingBottom:'50px', marginBottom: '1.45rem'}}>
              <h1 style={{color:colors.black}}>
                Insights
              </h1>
              <ReactMarkdown
              parserOptions={{ commonmark: true }}
                  source={insightsDescription}
                  escapeHtml={false}
                />
            </div>
            <div style={styles.headerSection}>
              <h2 style={styles.header}>Webinars</h2>
              <div style={styles.description}>
              <ReactMarkdown
              parserOptions={{ commonmark: true }}
                  source={webinarDescription}
                  escapeHtml={false}
                />
              </div>
              
            </div>
            <div style={styles.workshopSection}>
              {webinars.map((webinar, i) => (
                <Workshop key={i} data={webinar} lang={language} />
              ))}
            </div>
          </div>
          <div style={styles.lowerSection}>
            <div style={styles.headerSection}>
              <h2 style={styles.header}>Workshops</h2>
              <p style={styles.description}>
                {workshopDescription}
              </p>
            </div>
            <div style={styles.workshopSection}>
              {workshops.map((workshop, i) => (
                <Workshop key={i} data={workshop} lang={language} />
              ))}
            </div>
          </div>
        </div>

        <div style={styles.mediumSection}>
          <div style={styles.mediumContainer}>
            <div>
              <h2 style={styles.header}>
                {props.store.language === "en"
                  ? "Current Thinking"
                  : "Aktuelle Artikel"}
              </h2>
              <Medium />
            </div>
          </div>
        </div>
      </div>

      <ContactBanner backgroundColor={colors.white} />
      <Newsletter />
      </div>
    </Layout>
  )
}

export default inject("store")(observer(Insights))

const desktopStyles = {
  container: { backgroundColor: colors.greyBackground },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbContainer: { paddingBottom: "50px" },
  upperSection: { padding: "50px 0px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { display: "flex",marginBottom: '1.45rem', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  description: { width: "70%" },
  workshopSection: {
    width: "105%",
    display: "flex",
    flexWrap: "wrap",
    //justifyContent: "flex-start",
    //alignItems: "flex-start",
  },
  lowerSection: { padding: "50px 0px" },
  mediumSection: { backgroundColor: "white" },
  mediumContainer: {
    maxWidth: "80%",
    margin: "auto",
    padding: "50px 0px",
  },
}

const mobileStyles = {
  container: { backgroundColor: colors.greyBackground },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    //padding: "50px 0px",
  },
  breadCrumbContainer: { paddingBottom: "50px" },
  upperSection: { padding: "50px 0px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { display: "flex",flexDirection:'column', marginBottom: '1.45rem', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  description: { width: "100%" },
  workshopSection: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  lowerSection: { padding: "50px 0px" },
  mediumSection: { backgroundColor: "white" },
  mediumContainer: {
    maxWidth: "80%",
    margin: "auto",
    padding: "50px 0px",
  },
}
